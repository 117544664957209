/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'players': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.132 13.935a5.81 5.81 0 01-2.268-.829c.21-.177.438-.331.68-.46a.123.123 0 01.08-.04c1.555-1.11 3.538.085 5.33-1.929h-.01a4.1 4.1 0 00.718-1.12 3.976 3.976 0 00.14-2.789A4 4 0 003.996 8c.01.991.383 1.945 1.049 2.68-.25.12-.51.25-.749.39a5.295 5.295 0 00-.8.58c-.046.043-.09.09-.13.14a.021.021 0 01-.01-.02 5.914 5.914 0 01-1.27-4.814 6 6 0 0111.846.186.993.993 0 00.981.858 1 1 0 001-1.135 8.03 8.03 0 00-1.917-4.146c-.22-.26-.46-.5-.72-.72a7.969 7.969 0 00-10.557 0c-.26.22-.5.46-.72.72a7.97 7.97 0 000 10.557c.04.04.07.08.1.11a7.877 7.877 0 001.69 1.4c0 .01 0 .01.01.01s0 .01.01.01c.93.58 1.97.958 3.055 1.11a1 1 0 00.268-1.98zM7.996 6a2 2 0 110 4 2 2 0 010-4zm6 6c0-1 .1-2-1-2s-1 1-1 2c-1 0-2-.1-2 1s1 1 2 1c0 1-.1 2 1 2s1-1 1-2h1a1 1 0 001-1c0-1.106-1-1-2-1z" _fill="#898B9B"/>'
  }
})
